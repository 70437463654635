import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Containers/ContentContainer"
import Button from "../Core/Buttons/Button"
import { getCategoryLink } from "../../services/blog/link"

const CategoriesBlock = styled.div`
  padding: 60px 0 70px;
  text-align: center;
  @media (min-width: ${breakpoints.md}) {
    padding: 65px 10px 90px; 
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 65px 30px 90px; 
  }
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1.15;
  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: -.4px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 48px;
  }
`

const CategoriesList = styled.div`
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 180px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 580px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  a {
    box-sizing: border-box;
    height: 40px;
    width: 180px;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 13px 10px 12px;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`

const Categories = (props) => {
  const { title, categories } = props
  return (
    <CategoriesBlock>
      <ContentContainer>
        <Title>{title}</Title>
        {categories && categories.length > 0 && (
          <CategoriesList>
            {categories.map((category, index) => {
              const { slug, title } = category
              return (
                <ButtonContainer key={index}>
                  <Button href={getCategoryLink(slug)}>
                    <span>{title}</span>
                  </Button>
                </ButtonContainer>
              )
            })}
          </CategoriesList>
        )}
      </ContentContainer>
    </CategoriesBlock>
  )
}

export default Categories
