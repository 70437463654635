import React, {Suspense} from "react"
import HeroBlock from "../Core/HeroBlock"
import Categories from "./Categories"
import Fallback from "../Core/Fallback"

const ArticleList = React.lazy(() => import("./ArticleList"))
const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))

const PageContents = ({ blocks, categories, articles }) => {
  let pageBlocks = []
  const isSSR = typeof window === "undefined"

  blocks && blocks.forEach((block, index) => {
    const { __typename: type } = block
    // console.log(type)
    if (type === "ContentfulHeroBlock") {
      pageBlocks.push(<HeroBlock {...block} key={index} />)
    }
    if (type === "ContentfulTitleTextBlock") {
      pageBlocks.push(
        <Categories {...block} key={index} categories={categories} />
      )
    }
    if (type === "ContentfulPost") {
      pageBlocks.push(
        <React.Fragment key={index}>{!isSSR &&  <Suspense fallback={<Fallback/>}><ArticleList featured={block} articles={articles} show={9}  learn={true}/></Suspense>}</React.Fragment>
      )
    }
    if (type === "ContentfulQuizCallout") {
      
      pageBlocks.push(
        <React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><QuizCtaBlock {...block} white/></Suspense>}</React.Fragment>)
    }
  })

  return <React.Fragment>{pageBlocks}</React.Fragment>
}

export default PageContents
