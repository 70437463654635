import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Blog/PageContents"

const BlogPage = ({ location, data }) => {
  const path = location.pathname
  const { page, categories, articles } = data

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <PageContents
        blocks={blocks}
        categories={categories.nodes}
        articles={articles.nodes}
      />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query BlogpageQuery {
    page: contentfulPage(slug: { eq: "learn" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      blocks {
        ... heroFragment
        ... titleTextFragment
        ... articleCardFragment
        ... quizCalloutFragment
      }
    }
    categories: allContentfulCategory {
      nodes {
        slug
        title
      }
    }
    articles: allContentfulPost(sort: { fields: publishedDate, order: DESC }) {
      nodes {
        ...articleCardFragment
      }
    }
  }
`
